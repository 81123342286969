.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.72); // Przyciemnione tło
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: auto;

}

.popup-cont {
  background: #fff;
  margin-top: 50px;
  border-radius: 0px;
  padding: 0;
  width: 80%;
  max-width: 1024px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: popup-bounce 0.5s ease-in-out;
  /* Efekt animacji */


  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 36px;
    font-weight: 200;
    cursor: pointer;
    z-index: 2;
  }

  .short-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: #f7f7f7;
    z-index: 1;
  }

  h1 {
    position: relative;
    font-family: "Merriweather", serif;
    font-size: 48px;
    font-weight: 300;
    font-style: normal;
    margin: 150px 0 35px 385px;
    color: #333;
    z-index: 2;
  }

  .popup-image {
    position: relative;
    width: calc(100% - 155px);
    height: 300px;
    object-fit: cover;
    border-radius: 0px;
    margin-left: 155px;
    z-index: 3;
  }

  
  p {
    font-size: 1rem;
    font-family: "Quicksand", serif;
    color: #000000;
  }

  .post-categories {

    position: relative;
    margin-left: 385px;
    margin-bottom: 27px;
    z-index: 3;

    .category {
      display: inline-block;
      font-family: "Quicksand", serif;
      font-size: 20px;
      color: #333;
      margin-right: 20px;
    }
  }

  .popup-content2 {
    margin-left: 385px;

    h2,
    h3 {
      margin-bottom: 10px;
      font-family: "Quicksand", serif;
      font-weight: 600;
    }

    p {
      margin-bottom: 15px;
      font-family: "Quicksand", serif;
      line-height: 1.6;
    }

    ul {
      // margin-left: 20px;
      
    }
    ol {
      // margin-left: 20px;
      
    }
    li{
      margin-bottom: 15px;
      font-family: "Quicksand", serif;
    }


    img {
      max-width: 100%;
      height: auto;
      margin: 20px 0;
    }
  }
}

/* Definicja animacji */
@keyframes popup-bounce {
  0% {
    transform: scale(0.7);
    /* Start od mniejszej skali */
    opacity: 0;
    /* Całkowicie niewidoczne */
  }

  50% {
    transform: scale(1.02);
    /* Lekko powiększone */
    opacity: 1;
    /* Pełna widoczność */
  }

  70% {
    transform: scale(0.98);
    /* Minimalny ruch "powrotu" */
  }

  100% {
    transform: scale(1);
    /* Powrót do pełnej skali */
  }
}

// Animacja znikania
@keyframes popup-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}

.popup-hide {
  animation: popup-hide 0.4s ease-out forwards;
  /* forwards zapewnia utrzymanie stanu końcowego */
}