.main {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .menu-section {
    position: fixed;
    // width: 100%;
    top: 0;
    background-color: white;
    z-index: 10;
    height: 80px;

    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;

    &.hidden {
      opacity: 0;
      transform: translateY(-100%);
      transition-duration: 0.2s;
    }
    &.visible {
      opacity: 1;
      transform: translateY(0);
      transition-duration: 0.7s;
    }
  }

  .bg_text_button {
    position: absolute;
    top: 80px;
    width: 100%;
    height: calc(100vh - 160px);
    background-image: url("../../../public/images/color2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 6;

    .text_button {
      position: absolute;
      left: 50%;
      top: 120px;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;

      .display01 {
        .little {
          font-size: 48px;
        }
        color: #4a4a4a;
        font-family: "Merriweather", serif;
        font-size: 80px;
        font-weight: 200;
        font-style: i;
        text-align: center;
      }
      .main_button {
        

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 35px;
        padding: 10px 10px 10px 25px;


        span {
          
          color: #000000;;
          letter-spacing: 2px;
          font-family: "Lato", sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 22px;
        }
        .btn-icon {
         
          width: 40px;
          height: 40px;
          border: none;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            width: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .main-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 5;
  }
}

@media (max-width: 680px) {
  .main {
    .bg_text_button {
      .text_button {
        .display01 {

          
          color: #4a4a4a;
          font-family: "Merriweather", serif;
          font-size: 36px;
          font-weight: 200;
          font-style: normal;
          text-align: center;

          .little {
            font-size: 26px;
          }
        }
      }
    }
  }
}

