.panel {
  position: absolute;
  top: -1000px;
  right: 0;
  // border-bottom-left-radius: 50px;
  background-color: #ffffff;
  width: 100%;
  height: calc(100vh - 160px);
  display: flex;
  transition: top 0.5s ease;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);

  &.open {
    top: 80px;
  }

  .left-panel {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: transparent;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    gap: 20px;

    .panel-menu {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-self: flex-end;
      margin-right: 40px;
      gap: 40px;
      font-family: "Lato", serif;
      font-weight: 300;
      font-style: normal;

      .admin,
      .faq {
        cursor: pointer;

        &:hover {
          color: rgb(194, 194, 194);
        }
      }
    }

    .head-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-bottom: 50px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      h3 {
        font-family: "Merriweather", serif;
        font-weight: 200;
        font-size: 32px;
      }
    }

    .reminder-buttons {
      max-height: 500px;

      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 100px;
      overflow-y: auto;
      padding: 10px;
      position: relative;

      p {
        font-family: "Lato", serif;
        font-weight: 300;
        font-style: normal;
      }
      .reminder-btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;

        padding: 5px 5px 5px 5px;
        background-color: #ffffff;
        border-radius: 40px;
        border: none;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);

        .texts {
          display: flex;
          flex-direction: column;
          text-align: left;
          color: #000000;
          font-family: "Lato", serif;
          font-weight: 300;
          font-style: normal;
          margin: 0 40px 0 20px;

          .type {
            font-family: "Lato", serif;
            font-weight: 900;
            font-style: normal;
          }
        }

        .date-btn {
          border-radius: 50%;
          width: 70px;
          height: 70px;
          display: flex;
          flex-direction: column;
          background-color: #4e95ff;
          color: rgb(255, 255, 255);
          justify-content: center;
          align-items: center;
          font-family: "Lato", serif;
          font-weight: 300;
          font-style: normal;
        }

        .dots-wrapper {
          position: absolute;
          right: 10px;

          .dots-btn {
            width: 20px;
            height: 20px;
            border: none;
            border-radius: 50%;
            display: flex;
            color: rgb(0, 0, 0);
            background-color: transparent;
            justify-content: center;
            align-items: center;
            font-family: "Lato", serif;
            font-weight: 300;
            font-style: normal;
          }
        }
      }
    }

    h3 {
      font-family: "Merriweather", serif;
      font-weight: 100;
      font-style: normal;
      font-size: 22px;
      margin-bottom: 20px;
    }
    .main_button{
      width: 300px;
    }
    .logout-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #677c59;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      margin-top: 30px;
      color: white;
      width: 250px;
      padding: 15px 25px;

      font-family: "Lato", serif;
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
    }
  }

  .right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }

  .menu-tooltip {
    /* Animacja pojawiania się */
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(5px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .tooltip-option {
      display: block;
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      padding: 8px 12px;
      font-size: 16px;
      color: #252525;
      cursor: pointer;
    }

    .tooltip-option:hover {
      background-color: #f5f5f5;
    }
  }
}

@media (max-width: 680px) {
  .panel {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    .left-panel {
      .head-account {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        margin-bottom: 50px;
        

        
      }
      h3 {
        text-align: center;
        

        span{
          text-align: center;
          margin-bottom: 40px;
          

        };
      }
      
    }
    .right-panel {
      display: none;
    }
  }
}
