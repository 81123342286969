.menu {
  //   position: fixed;
  //   background-color: white;
  //   width: 1440px;
  //   max-width: 1440px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 80px;
  padding: 0 40px;

  .logo {
    flex: 1;
    span {
      color: #4a4a4a;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 20px;
    }
  }
  .admin {
    cursor: pointer;
    transition: color 0.2s ease;
  }

  .admin:hover {
    color: #007bff;
  }
  .przewodnik {
    font-family: "Lato", sans-serif;
    color: white;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    background-color: rgb(255, 154, 154);
    padding: 8px 12px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 20px;

    &:hover{
      background-color: rgb(255, 0, 0);
    }
  }
  .menu-burger {
    cursor: pointer;
  }
}
