@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Fredoka:wght@300..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Quicksand:wght@300..700&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body {
  position: relative;
    scrollbar-width: none; /* Ukryj paski przewijania w Firefox */

    .menu-tooltip {
      z-index: 9999;
      background-color: white;
      border: 1px solid #ddd;
      box-shadow: 0px 4px 16px rgba(0,0,0,0.1);
      padding: 10px;
      display: flex;
      flex-direction: column;
      opacity: 1;
      


            
      /* Animacja pojawiania się */
      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(5px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      .tooltip-option {
        display: block;
        background: none;
        border: none;
        width: 100%;
        text-align: left;
        padding: 8px 12px;
        font-size: 16px;
        color: #252525;
        cursor: pointer;
      }
  
      .tooltip-option:hover {
        background-color: #f5f5f5;
      }
    }
    
  }
  
  ::-webkit-scrollbar {
    display: none; /* Ukryj paski przewijania w przeglądarkach WebKit (Chrome, Safari) */
  }